<template>
  <div>
    <b-table class="guest-table" :items="items" :fields="fields" responsive sticky-header="70vh">
      <template v-slot:thead-top="data">
        <b-tr>
          <b-th class="thline" colspan="2">Total a ingresar: {{total}} {{totalSymbol}} </b-th>
          <b-th class="thline" colspan="5">Fecha prevista del pago: 31/06/2020</b-th>
        </b-tr>
      </template>
    </b-table>
  </div>
</template>
<script>
import Amplify from 'aws-amplify'
import utils from '@/api/utils.js'
import { EventBus } from '@/event-bus'

export default {
  data() {
    return {
      total: 50,
      totalSymbol: this.$store.state.user.currencySymbol,
      cantidad: 10,
      items: [
        { name: 'Barcos Hidalgo', amount: `${this.cantidad}10€`, date: "05/06/2020" },
        { name: 'Floristería Loli', amount: "10€", date: "03/05/2020" },
        { name: 'Coolmod', amount: "10€", date: "21/05/2020" },
        { name: 'TechTec', amount: "10€", date: "10/04/2020" },
        { name: 'Agua Esteban', amount: "10€", date: "15/06/2020" },
      ],
      fields: [
        { key: 'name', label: 'Nombre' },
        { key: 'amount', label: 'Importe' },
        { key: 'date', label: 'Fecha de alta' },
      ],
    }
  },
  methods: {
    async initialGetUserData(dataUserId) {
      await this.$initMainData(dataUserId)
      // FIN Get user
    },
    onError: function () {
      return true
    },
  },
  mounted() {
    Amplify.Auth.currentSession().then(apiSession => {
      const paramsCheckAdmin = apiSession.accessToken.payload['cognito:groups']
      this.$store.dispatch('updateAdmin', utils.isAdminUser(paramsCheckAdmin))
      if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount !== '') {
        this.initialGetUserData(this.$store.state.user.id)
        this.$acl.change('admin')
      } else if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount === '') {
        this.$vs.loading.close()
        this.$acl.change('admin')
        this.$router.push('/admin-page')
      } else {
        this.initialGetUserData('0')
        this.$vs.loading.close()
      }
    })
    EventBus.$on('update-sales-screen', (salesAccounts) => {
      this.items = salesAccounts
    })
  },
}
</script>
<style>
.guest-table table thead tr th {
  font-size: 1rem;
}
.thline {
  background-color: #2f1b7a !important;
  color: white !important;
}
</style>