var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        staticClass: "guest-table",
        attrs: {
          items: _vm.items,
          fields: _vm.fields,
          responsive: "",
          "sticky-header": "70vh"
        },
        scopedSlots: _vm._u([
          {
            key: "thead-top",
            fn: function(data) {
              return [
                _c(
                  "b-tr",
                  [
                    _c(
                      "b-th",
                      { staticClass: "thline", attrs: { colspan: "2" } },
                      [
                        _vm._v(
                          "Total a ingresar: " +
                            _vm._s(_vm.total) +
                            " " +
                            _vm._s(_vm.totalSymbol) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "b-th",
                      { staticClass: "thline", attrs: { colspan: "5" } },
                      [_vm._v("Fecha prevista del pago: 31/06/2020")]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }